import opeImg from "../assets/opeB.jpg";

export const latestNews = {
  img: "https://evrfutev3ud.exactdn.com/wp-content/uploads/2024/09/image-3.jpg?strip=all&lossy=1&ssl=1",
  date: "September 20, 2024",
  title: "Tech City",
  desc: "Vesti’s Graduate Trainee Programme is How They Plan to Find Africa’s Best Talent",
  link: "https://www.techcityng.com/vesti-graduate-trainee-program-success/",
};

export const previousNews = [
  {
    img: "https://i0.wp.com/techeconomy.ng/wp-content/uploads/2024/09/Vesti.jpg?fit=1126%2C672&ssl=1",
    date: "September 20, 2024",
    title: "Tech Economy",
    desc: "Vesti’s Graduate Trainee Programme is How They Plan to Find Africa’s Best Talent",
    link: "https://techeconomy.ng/vestis-graduate-trainee-programme-is-how-they-plan-to-find-africas-best-talent/",
  },
  {
    img: "https://d1jcea4y7xhp7l.cloudfront.net/wp-content/uploads/2024/08/Ariyo-1-696x470.jpg",
    date: "August 21, 2024",
    title: "The Guardian",
    desc: "With A Vision in Ink, Vesti, David deepen Nigerian's diversity through art",
    link: "https://guardian.ng/art/with-a-vision-in-ink-vesti-david-deepen-nigerias-diversity-through-art/",
  },
  {
    img: "https://d1jcea4y7xhp7l.cloudfront.net/wp-content/uploads/2024/08/Ariyo-1-696x470.jpg",
    date: "August 13, 2024",
    title: "The Sun",
    desc: "Fola David’s record-breaking artwork: Vesti pledges cultural unity",
    link: "https://thesun.ng/fola-davids-record-breaking-artwork-vesti-pledges-cultural-unity/",
  },
  {
    img: "https://techpoint.africa/wp-content/uploads/2024/07/Vest-Banner-Expansion-scaled.jpg.webp",
    date: "August 01, 2024",
    title: "Techpoint",
    desc: "Vesti Hints Expansion to Bangladesh, India, and Pakistan, Heads for Series A",
    link: "https://techpoint.africa/2024/08/01/vesti-hints-expansion-to-bangladesh-india-and-pakistan-heads-for-series-a/",
  },
  {
    img: "https://c76c7bbc41.mjedge.net/wp-content/uploads/tc/2024/08/IMG_2238-2048x1152.jpg",
    date: "August 01, 2024",
    title: "Techcabal",
    desc: "Vesti Hints Expansion to Bangladesh, India, and Pakistan, Heads for Series A",
    link: "https://techcabal.com/2024/08/01/vesti-hints-expansion-to-bangladesh-india-and-pakistan-heads-for-series-a/",
  },
  {
    img: opeImg,
    date: "July 4th, 2024",
    title: "The Guardian",
    desc: "How artificial intelligence is unlocking personalized support for modern customer experiences",
    link: "https://guardian.ng/how-artificial-intelligence-is-unlocking-personalized-support-for-modern-customer-experiences/#google_vignette",
  },
  {
    img: opeImg,
    date: "July 3rd, 2024",
    title: "The Sun",
    desc: "How Artificial Intelligence is unlocking personalized support for modern customer experiences",
    link: "https://thesun.ng/how-artificial-intelligence-is-unlocking-personalized-support-for-modern-customer-experiences/?amp",
  },
  {
    img: "https://global.ariseplay.com/amg/www.thisdaylive.com/uploads/5c57cc7c-da17-474d-872a-504e244331ed.jpeg",
    date: "June 19th, 2024",
    title: "THIS DAY",
    desc: "The Power of Storytelling for Growth-stage Companies.",
    link: " https://www.thisdaylive.com/index.php/2024/06/19/the-power-of-storytelling-for-growth-stage-companies/",
  },
  {
    img: "https://c76c7bbc41.mjedge.net/wp-content/uploads/tc/2024/06/Officiual-Partnership-690x388.jpg",
    date: "June 19th, 2024",
    title: " Techcabal",
    desc: "Vesti teams up with Prodigy Finance to Bridge the $600 Million Student Funding Gap in Africa.",
    link: "https://techcabal.com/2024/06/19/vesti-teams-up-with-prodigy-finance-to-bridge-the-600-million-student-funding-gap-in-africa/",
  },

  {
    img: "https://techpoint.africa/wp-content/uploads/2024/06/Future-awards-the.jpg",
    date: "June 14th, 2024",
    title: "Techpoint",
    desc: "Vesti Partners Future Awards Africa, to Invest $250,000 in Talented Youth",
    link: "https://techpoint.africa/2024/06/14/vesti-partners-future-awards-africa-to-invest-250000-in-talented-youth/",
  },

  {
    img: "https://leadership.ng/wp-content/uploads/2024/06/Untitled-design-7-750x422.png",
    date: "June 13th, 2024",
    title: "Leadership",
    desc: "Vesti, Future Awards Partner Plan $250,000 Investment In Talented Youths",
    link: "https://leadership.ng/vesti-future-awards-partner-plan-250000-investment-in-talented-youths/",
  },

  {
    img: "https://global.ariseplay.com/amg/www.thisdaylive.com/uploads/327990f8-0011-41e0-a672-b4513961e6e7.jpeg",
    date: "June 12th, 2024",
    title: "This Day",
    desc: "Vesti Set to Invest $250,000 in Youths ",
    link: "https://www.thisdaylive.com/index.php/2024/06/13/vesti-set-to-invest-250000-in-youths/",
  },

  {
    img: "https://guardian.ng/wp-content/uploads/2024/06/whatsapp_image_2024-06-12_at_11.58.26.jpeg",
    date: "June 12th, 2024",
    title: "The Guardian",
    desc: "Fintech firm, TFAA partner to empower young Africans ",
    link: "https://guardian.ng/business-services/fintech-firm-tfaa-partner-to-empower-young-africans/",
  },

  {
    img: "https://ocdn.eu/pulscms-transforms/1/2grk9kpTURBXy84ZDdiMzU4MDdlZTMyMDJiMTdhMTIzMzA1YWU3ZjMwMy5qcGeSlQMAzQEnzQjKzQTxkwXNAxbNAa7eAAKhMAahMQA",
    date: "June 12th, 2024",
    title: "Pulse",
    desc: "Vesti partners Future Awards Africa to invest $250,000 in talented youth",
    link: "https://www.pulse.ng/business/domestic/vesti-partners-future-awards-africa-to-invest-dollar250000-in-talented-youth/g7bkc0e",
  },

  {
    img: "https://dailypost.ng/wp-content/uploads/2024/06/MixCollage-11-Jun-2024-12-45-PM-4857.jpg",
    date: "June 11th, 2024",
    title: "Daily Post",
    desc: "Vesti Partners Future Awards Africa, to Invest $250,000 in Talented Youth",
    link: "https://dailypost.ng/2024/06/11/vesti-partners-future-awards-africa-to-invest-250000-in-talented-youth/",
  },

  {
    img: "https://cdn.businessday.ng/wp-content/uploads/2024/05/20240508_070751_0000.png",
    date: "May 8th, 2024",
    title: "Business Day",
    desc: "Vesti unveils AI-powered app for Nigerian migrants",
    link: "https://techsafari.beehiiv.com/p/migration-service",
  },

  {
    img: "https://cdn.businessday.ng/wp-content/uploads/2024/05/20240508_070751_0000.png",
    date: "May 7th, 2024",
    title: "Tech Point",
    desc: "Vesti Selected for the Prestigious #YASR30 2024 List by the Yale Africa Startup Review!",
    link: "https://techpoint.africa/2024/05/07/vesti-selected-for-the-prestigious-yasr30-2024-list-by-the-yale-africa-startup-review/",
  },
  {
    img: "https://assets.sunnewsonline.com/wp-content/uploads/2024/05/images-3.jpg",
    date: " May, 1st 2024",
    title: "Sun News Online",
    desc: "Vesti unveils features to transform migration journeys",
    link: "https://sunnewsonline.com/vesti-unveils-features-to-transform-migration-journeys/",
  },
  {
    img: "",
    date: "April 29th, 2024",
    title: "Guardian",
    desc: "Firm Introduce AI financial tools to simplify cross-border transactions",
    link: "https://guardian.ng/business-services/firm-introduces-ai-financial-tools-to-simplify-cross-border-transactions/",
  },
  {
    img: "https://i0.wp.com/brandcrunch.com.ng/wp-content/uploads/2024/04/VESTI-MIAI-MIGRATION.jpeg?fit=528%2C350&ssl=1",
    date: "April 29th, 2024",
    title: "Brand Crunch",
    desc: "Vesti Unveils Miai, Safelock to Revolutionize Migration Journeys, Targets $36b Global Market",
    link: "https://brandcrunch.com.ng/2024/04/29/vesti-unveils-miai-safelock-to-revolutionize-migration-journeys-targets-36b-global-market/",
  },

  {
    img: "https://itpulse.com.ng/wp-content/uploads/2024/04/vestiiiiii.jpg",
    date: "April 29th, 2024",
    title: "ITPULSE",
    desc: "Vesti launches two solutions to transform migration journey",
    link: "https://itpulse.com.ng/2024/04/29/vesti-launches-two-solutions-to-transform-migration-journey/",
  },
  {
    img: "https://i0.wp.com/nigerianjournal.com.ng/wp-content/uploads/2024/04/Image-1.jpeg?resize=768%2C512&ssl=1",
    date: "April 29th, 2024",
    title: "Nigerian Journal",
    desc: "#Vesti Unveils Groundbreaking #Miai and #Safelock Features to Revolutionize #Immigrant Financial Services",
    link: "https://nigerianjournal.com.ng/vesti-unveils-groundbreaking-miai-and-safelock-features-to-revolutionize-immigrant-financial-services/",
  },
  {
    img: "https://i0.wp.com/techeconomy.ng/wp-content/uploads/2024/04/Vesti-Introduces-%E2%80%98Miai-by-Vesti-and-%E2%80%98Safelock-by-Vesti-1-e1714370869224.jpeg?fit=1159%2C660&ssl=1",
    date: "April 29th, 2024",
    title: "Tech Economy",
    desc: "Vesti Introduces ‘Miai by Vesti’ and ‘Safelock by Vesti’ to Revolutionizes Migration Journey",
    link: "https://techeconomy.ng/vesti-introduces-miai-by-vesti-and-safelock-by-vesti-to-revolutionizes-migration-journey/",
  },
  {
    img: "https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEigCNY1X5CZpyhEuE0TatvpzCd8NY7FzvjtdLrv1sLva-QZtAlSZ8TN2hSqSqbCjs-o7iBxd65r7-wjw-jiGBmHuXJyspGNYuQspl7Eh0Co8zO41rSjumynRgf6h3oik2S2_TTGGmeFEjMifEAVagwYSsN4sVrAJBJphvujUCMEkl2CeSxITj5XN-9cEOA/s1600/Vesti%20Redefines%20Migration%20Landscape%20with%20Launch%20of%20Miai%20and%20Safelock%20-%20brandarena.com.ng.jpeg",
    date: "April 28th, 2024",
    title: "Brandarena",
    desc: "Vesti Redefines Migration Landscape with Launch of Miai and Safelock",
    link: "https://www.brandarena.com.ng/2024/04/vesti-redefines-migration-landscape.html",
  },
  {
    img: "https://www.brandimpact.com.ng/wp-content/uploads/2024/04/Image-2.jpeg",
    date: "April 25th,  2024",
    title: "Brand Impact",
    desc: "Vesti revolutionizes migration journeys with launch of Miai by Vesti",
    link: "https://www.brandimpact.com.ng/2024/04/28/vesti-revolutionizes-migration-journeys-with-launch-of-miai-by-vesti/",
  },
  {
    img: "https://global.ariseplay.com/amg/www.thisdaylive.com/uploads/807e0e01-7ad8-4258-8f70-6ee6cec14ca5.jpg",
    date: "Apr 8th, 2024",
    title: "Tech Cabal",
    desc: "6 Months After UK Launch, Vesti Soars into Accenture’s Fintech in London Ignite Prestigious Program 2024",
    link: "https://techcabal.com/2024/04/08/6-months-after-uk-launch-vesti-soars-into-accentures-fintech-in-london-ignite-prestigious-program-2024/",
  },
  {
    img: "https://c76c7bbc41.mjedge.net/wp-content/uploads/tc/2024/04/Abimbola-Amusan-1-1050x1313.jpg",
    date: "April 8th, 2024",
    title: "Tech Cabal",
    desc: "Vesti’s WorldForce™ will see 200 Africans join the US’s growing technology workforce, after last week’s H-1B Visa draws.",
    link: "https://techcabal.com/2024/04/08/vestis-worldforce-will-see-200-africans-join-the-uss-growing-technology-workforce-after-last-weeks-h-1b-visa-draws/",
  },
  {
    img: "https://c76c7bbc41.mjedge.net/wp-content/uploads/tc/2024/04/Olusola-Amusan-1-768x1024.jpeg",
    date: "April 8th, 2024",
    title: "Tech Cabal",
    desc: "6 Months After UK Launch, Vesti Soars into Accenture’s Fintech in London Ignite Prestigious Program 2024",
    link: "https://c76c7bbc41.mjedge.net/wp-content/uploads/tc/2024/04/Olusola-Amusan-1-768x1024.jpeg",
  },
  {
    img: "https://cdn.businessday.ng/2023/09/nigerian-fintechs-1.jpeg",
    date: "April 2, 2024",
    title: "Business Day",
    desc: "How tech businesses can leverage operations efficiency to scale",
    link: "https://businessday.ng/companies/article/how-tech-businesses-can-leverage-operations-efficiency-to-scale/",
  },
  {
    img: "https://assets.sunnewsonline.com/wp-content/uploads/2024/03/thumbnail-5-9.jpg",
    date: "12th March 2024",
    title: "The Sun",
    desc: "Growing Operations for an Ambitious Company",
    link: "https://sunnewsonline.com/growing-operations-for-an-ambitious-company/",
  },
  {
    img: "https://global.ariseplay.com/amg/www.thisdaylive.com/uploads/Vesti-Partners-NITDA-.jpg",
    date: "January 8th, 2024",
    title: "This Day",
    desc: "Vesti Partners NITDA to Empower Immigrants",
    link: "https://www.thisdaylive.com/index.php/2024/02/02/vesti-partners-nitda-to-empower-immigrants/",
  },
  {
    img: "https://global.ariseplay.com/amg/www.thisdaylive.com/uploads/Screenshot_20231109-212253.jpg",
    date: "November 11th, 2023",
    title: "This Day ",
    desc: "How Immigrant Talents Can Foster An Inclusive Global Workforce",
    link: "https://www.thisdaylive.com/index.php/2023/11/08/how-immigrant-talents-can-foster-an-inclusive-global-workforce/",
  },
  {
    img: "https://assets.sunnewsonline.com/wp-content/uploads/2023/11/thumbnail-11-2.jpg",

    date: "November 10th, 2023",
    title: "Daily Sun",
    desc: "Vesti building AI copilot for immigrants",
    link: "https://sunnewsonline.com/vesti-building-ai-copilot-for-immigrants/",
  },
  {
    img: "",

    date: "October 14th, 2023",
    title: "Aridigest Fintech Review",
    desc: "Afridigest Fintech Review: One way to think about fintech in Africa",
    link: "https://open.substack.com/pub/afridigest/p/afr-week-41-2023?utm_campaign=post&utm_medium=web",
  },
  {
    img: "https://c76c7bbc41.mjedge.net/wp-content/uploads/tc/2023/10/0A7A0789-1050x700.jpg",

    date: "October 11th, 2023",
    title: "Techcabal",
    desc: "Techstars-backed migration fintech Vesti, launches in the United Kingdom",
    link: "https://techcabal.com/2023/10/11/techstars-backed-migration-fintgech-vesti-launches-in-the-united-kingdom/",
  },

  /// older
  {
    img: "https://c76c7bbc41.mjedge.net/wp-content/uploads/tc/2023/10/Featured-Image.jpg",
    date: "October 7th, 2023",
    title: "Techcabal ",
    desc: "Vesti Unveils Cutting-Edge Migration Fintech Solution in Zambia",
    link: "https://techcabal.com/2023/10/07/vesti-unveils-cutting-edge-migration-fintech-solution-in-zambia/ ",
  },

  {
    img: "https://www.accesswire.com/users/newswire/images/789416/AJY3EnmRT1IENzQa8bNYekviVs7NLkPbiSwSUKAUJhLSmpFf-TEug94tfw5WAG101qbkKQ6hFVOisqEKy6HZALq9Z91mo4xq-YA5RiddJd8-7FHEOToS0XwTy7ujHJDFAgyBUE_kKJayjKFX8OQQxh8.jpg",

    date: "October 3rd, 2023",
    title: "ACCESSWIRE",
    desc: "Vesti Launches Element to Bank US-bound African Founders",
    link: "https://www.beardsleyfe.com/markets/stocks.php?article=accwirecq-2023-10-2-vesti-launches-element-to-bank-us-bound-african-founders",
  },
  {
    img: "	https://i0.wp.com/trenderhq.com/wp-content/uploads/2023/10/Vesti-Clear.jpg",

    date: "October 2nd, 2023",
    title: "TenderHQ",
    desc: "Vesti: Making Global Opportunities Accessible",
    link: "https://trenderhq.com/vesti-making-global-opportunities-accessible/",
  },

  {
    img: "https://cdn.ghanaweb.com/imagelib/pics/545/54532814.295.jpg",

    date: "September 21, 2023",
    title: "GhanaWeb",
    desc: "The significance of legal documentation in international travel: Insights from Ariyo-Agbaje Ifeoluwa",
    link: "https://www.ghanaweb.com/GhanaHomePage/features/The-significance-of-legal-documentation-in-international-travel-Insights-from-Ariyo-Agbaje-Ifeoluwa-1848332 ",
  },
  {
    img: "	https://assets.sunnewsonline.com/wp-content/uploads/2023/09/thumbnail-37.jpg",

    date: "September 15th, 2023",
    title: "Daily Sun",
    desc: "Financial inclusion for immigrants will boost economy of host countries",
    link: "https://sunnewsonline.com/financial-inclusion-for-immigrants-will-boost-economy-of-host-countries/",
  },

  // {
  //   img: "https://www.bulabagh.com/wp-content/uploads/2023/08/Vesti-Grand-Launch-64-780x470.jpg",

  //   date: "August 11th, 2023",
  //   title: "Bulaba Gh",
  //   desc: "Vesti Launches Ghana Branch to help Immigrants and related Financial Support Services",
  //   link: "https://www.bulabagh.com/vesti-launches-ghana-branch-to-help-immigrants-and-related-financial-support-services/",
  // },
  {
    img: "https://c76c7bbc41.mjedge.net/wp-content/uploads/tc/2023/07/Element-intro-1024x1024.jpg",

    date: "July 17th, 2023",
    title: "Techcabal",
    desc: "Element by Vesti, a Digital Banking Solution Launched at Lagos Startup Week 2023",
    link: "https://techcabal.com/2023/07/17/element-by-vesti-a-digital-banking-solution-launched-at-lagos-startup-week-2023/",
  },
  {
    img: "https://assets.sunnewsonline.com/wp-content/uploads/2023/07/thumbnail-11.jpg",

    date: "July 6th, 2023",
    title: "Daily Sun",
    desc: "Using unified framework to tackle money laundering in Africa",
    link: "https://sunnewsonline.com/using-unified-framework-to-tackle-money-laundering-in-africa/",
  },
  {
    img: "https://techpoint.africa/wp-content/uploads/2023/06/lagos-startup-headline-scaled.jpg",

    date: "July 1st, 2023",
    title: "Techpoint",
    desc: "Vesti Reaffirms Commitment to Startup Ecosystem, Headlines Lagos Startup Week 2023",
    link: "https://techpoint.africa/2023/06/01/vesti-reaffirms-commitment-to-startup-ecosystem-headlines-lagos-startup-week-2023/",
  },

  {
    img: "",

    date: "May 17th, 2023",
    title: "The Sun",
    desc: "Vesti offers Maldives getaway to Hilda Baci",
    link: "https://thestreetjournal.org/vesti-app-offers-hilda-baci-all-expense-paid-trip-to-maldives-for-summer/",
  },

  {
    img: "https://cdn.vanguardngr.com/wp-content/uploads/2023/05/satbusvesti-pix-scaled.jpeg",

    date: "May 13th, 2023",
    title: "Vanguard",
    desc: "2023 Vesti Fair targets empowering the global citizen",
    link: "https://www.vanguardngr.com/2023/05/2023-vesti-fair-targets-empowering-the-global-citizen/",
  },
  {
    img: "https://c76c7bbc41.mjedge.net/wp-content/uploads/tc/2023/05/book-a-flightt-768x768.jpg",

    date: "May 8th, 2023",
    title: "Techcabal",
    desc: "Vesti’s New Partnership Brings Low Prices on Flights to Users",
    link: "https://techcabal.com/2023/05/08/vestis-new-partnership-brings-low-prices-on-flights-to-users/",
  },
  {
    img: "https://technext24.com/wp-content/uploads/2023/05/Co-founder-and-CEO-of-Vesti.png",

    date: "May 21st, 2023",
    title: "Technext",
    desc: "Vesti’s Olusola Amusan is helping Nigerian students migrate abroad with loans, payments, advice",
    link: "https://technext24.com/2023/05/21/vesti-is-helping-students-migrate-to-us/ ",
  },
  {
    img: "https://c76c7bbc41.mjedge.net/wp-content/uploads/tc/2023/04/Agy-mipango-pic-1.jpg",

    date: "April 20th, 2023",
    title: "Techcabal",
    desc: "15 founders building solutions to look out for in 2023",
    link: "https://techcabal.com/2023/04/20/15-founders-building-solutions-to-look-out-for-in-2023/",
  },
  {
    img: "	https://assets.sunnewsonline.com/wp-content/uploads/2023/04/Untitled-29.jpg",

    date: "April 2nd, 2023",
    title: "The Sun",
    desc: "Challenges African migrants face in accessing financial opportunities —Amusan, Vesti Technologies CEO",
    link: "https://sunnewsonline.com/challenges-african-migrants-face-in-accessing-financial-opportunities-amusan-vesti-technologies-ceo/#google_vignette",
  },

  {
    img: "",

    date: "21 March, 2023",
    title: "Tech Cabal",
    desc: "Vesti Technology Solutions Inc. and MPower Financing Announce Partnership",
    link: "https://techcabal.com/2023/03/21/vesti-technology-solutions-inc-and-mpower-financing-announce-partnership/",
  },
  {
    img: "",
    date: "7 March, 2023",
    title: "Technova Ghana",
    desc: "Vesti, A Financial Service Provider For Immigrants, Plans to Expand To Ghana In Q2 2023",
    link: "https://technovagh.com/vesti-a-financial-service-provider-for-immigrants-plans-to-expand-to-ghana-in-q2-2023/",
  },
  {
    img: "",
    date: "6 March, 2023",
    title: "Tech Point Africa",
    desc: "Vesti hints expansion to Ghana, Zambia and Mexico in Q2 to tackle Immigrants that are US bound",
    link: "https://techpoint.africa/2023/03/06/vesti-hints-expansion-to-ghana-zambia-and-mexico-in-q2-to-tackle-immigrants-that-are-us-bound/",
  },
  {
    img: "",
    date: "6 March, 2023",
    title: "Tech Cabal",
    desc: "Vesti hints expansion to Ghana, Zambia and Mexico in Q2 to tackle Immigrants that are US bound",
    link: "https://techcabal.com/2023/03/06/vesti-hints-expansion-to-ghana-zambia-and-mexico-in-q2-to-tackle-immigrants-that-are-us-bound/",
  },
  {
    img: "",
    date: "18 Feb, 2023",
    title: "This Day",
    desc: "Vesti Technologies Unveils Promo to Reward Users",
    link: "https://www.thisdaylive.com/index.php/2023/02/18/vesti-technologies-unveils-promo-to-reward-users/",
  },
  {
    img: "",
    date: "6 Feb, 2023",
    title: "SunNews Online",
    desc: "Vesti, Shuttlers partner to grow Nigeria’s transportation",
    link: "https://sunnewsonline.com/vesti-shuttlers-partner-to-grow-nigerias-transportation/",
  },
  {
    img: "",
    date: "5 Feb, 2023",
    title: "Guardian Newspaper",
    desc: "Our commitment to tailored payment products unwavering",
    link: "https://guardian.ng/business-services/our-commitment-to-tailored-payment-products-unwavering/",
  },
  {
    img: "",
    date: "31 Jan, 2023",
    title: "Business Day",
    desc: "Shuttlers, Vesti partner to ease commuting",
    link: "https://businessday.ng/companies/article/shuttlers-vesti-partner-to-ease-commuting/",
  },
  {
    img: "",
    date: "30 Jan, 2023",
    title: "Tech Cabal",
    desc: "Vesti, Shuttlers partner to increase brand equity",
    link: "https://techcabal.com/2023/01/30/vesti-shuttlers-partner-to-increase-brand-equity/",
  },
  {
    img: "",
    date: "26 Jan, 2022",
    title: "Tech Cabal",
    desc: "Migrant’s banking app, Vesti raises $500k in pre-seed funding",
    link: "https://techcabal.com/2022/01/26/migrants-banking-app-vesti-raises-500k-in-pre-seed-funding/",
  },
  {
    img: "",
    date: "16 Dec, 2022",
    title: "The SunNews Online",
    desc: "Amusan accepted into Forbes Business Council",
    link: "https://sunnewsonline.com/amusan-accepted-into-forbes-business-council/",
  },
  {
    img: "",
    date: "11 May, 2021",
    title: "Techcity",
    desc: "Latest innovation by Amusan brothers",
    link: "https://www.techcityng.com/tag/latest-innovation-by-amusan-brothers/",
  },
  {
    img: "",
    date: "11 May, 2021",
    title: "Techcity",
    desc: "Vesti app wants to make payment and money management easier for migrants.",
    link: "https://www.techcityng.com/vesti-app-a-redefining-solution-for-peer-to-peer-payments-and-more-courtesy-of-the-amusan-brothers/",
  },
  {
    img: "",
    date: "10 May, 2021",
    title: "Techpoint",
    desc: "Amusan Brothers build Vesti app, a financial solution helping to solve peer-to-peer payments and migration related fees settlement",
    link: "https://techpoint.africa/2021/05/10/amusan-brothers-build-vesti-app-a-financial-solution-helping-to-solve-peer-to-peer-payments-and-migration-related-fees-settlement/",
  },
];
